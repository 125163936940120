<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.62356 3.2353C10.6553 2.92157 11.7534 2.92157 12.7851 3.2353L20.647 5.62593C21.8368 5.98773 22.1993 7.34296 21.6739 8.27798V16.6229L23.1076 17.7499C23.5462 18.0946 23.6297 18.7394 23.2941 19.19C22.9585 19.6406 22.3309 19.7264 21.8923 19.3816L21.6739 19.2099V19.9727C21.6739 20.5401 21.2262 21 20.6739 21C20.1216 21 19.6739 20.5401 19.6739 19.9727V19.2099L19.4555 19.3816C19.0168 19.7264 18.3892 19.6406 18.0536 19.19C17.718 18.7394 17.8015 18.0946 18.2401 17.7499L19.6739 16.6229V9.49853L18.9347 9.7982V15.2829C18.9347 15.5783 18.8109 15.8594 18.5949 16.0545C18.3789 16.2495 18.0917 16.3396 17.8065 16.3017L16.2179 16.0906C12.8115 15.638 9.36244 15.638 5.95599 16.0906L4.36743 16.3017C4.08223 16.3396 3.79496 16.2495 3.57896 16.0545C3.36297 15.8594 3.23916 15.5783 3.23916 15.2829V9.70302L1.61497 9.04451C0.0569459 8.41283 0.155594 6.11431 1.76167 5.62593L9.62356 3.2353ZM13.249 12.1034L16.9348 10.6091V14.1142L16.4745 14.053C12.8977 13.5777 9.27622 13.5777 5.69944 14.053L5.23916 14.1142V10.5139L9.15972 12.1034C10.4735 12.6361 11.9352 12.6361 13.249 12.1034ZM12.2175 5.20539C11.5562 5.00432 10.8524 5.00433 10.1912 5.20539L2.99274 7.3943L9.89393 10.1923C10.7359 10.5337 11.6727 10.5337 12.5147 10.1923L19.4159 7.3943L12.2175 5.20539Z"
    />
  </svg>
</template>
